import React, { Component } from 'react';
import { Form, Templates } from 'react-formio';
import { getComponent } from 'formiojs/utils/utils.js';
import './MoStripeCheckoutComponent.js';
import logo from './logo.svg';
import URLSearchParams from 'url-search-params';
import { loadCSS } from 'fg-loadcss';
import './App.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import WebFont from 'webfontloader';
import $ from 'jquery';
import * as Sentry from '@sentry/browser';

import { Formio } from 'react-formio';
import flatpickr from "flatpickr"
import { it } from "flatpickr/dist/l10n/it.js"
import { fr } from "flatpickr/dist/l10n/fr.js"
import { nl } from "flatpickr/dist/l10n/nl.js"
import { de } from "flatpickr/dist/l10n/de.js"
import { es } from "flatpickr/dist/l10n/es.js"
import { pt } from "flatpickr/dist/l10n/pt.js"
import { no } from "flatpickr/dist/l10n/no.js"
import { pl } from "flatpickr/dist/l10n/pl.js"
import { da } from "flatpickr/dist/l10n/da.js"
import { sv } from "flatpickr/dist/l10n/sv.js"
import { ro } from "flatpickr/dist/l10n/ro.js"

//var formio_backend = "https://momentum-form-dashboard.kampadmin.be"
//var formio_backend = "https://momentum-podio-api.herokuapp.com"
var formio_backend = "https://momentum-podio-api.herokuapp.com"

Formio.setProjectUrl(formio_backend + "/forms_recaptcha/");
Templates.framework = 'bootstrap3';

class App extends Component {
  constructor(props) {
    super(props)
    if (document.getElementById('momentumtools-form').getAttribute('set-manifest') !== null) {
      Sentry.init({
        dsn: "https://c2d1ead6858645858f7d5381c813455e@sentry.io/1374345"
      });
    }
    this.onError = this.onError.bind(this)
    this.state = {
      form: this.getFormFromURLSearchParamsOrElem(),
      item_id: this.getItemIdFromURLSearchParams(),
      formObj: {},
      submissionObj: null,
      submitted: false,
      errorMessage: null,
      metadata: {
        title: null, success_message: null, submit_type: "success-message",
        success_redirect_url: this.getRedirectUrlFromURLSearchParams(), i18n: {}, form_revision_id: null
      }
    }
  }

  setStyle(style) {
    if (style.cssType === "basic") {
      loadCSS("https://bootswatch.com/3/flatly/bootstrap.min.css");

      if (style.style.fontSize) {
        $('body').css("font-size", style.style.fontSize + "px")
        $('body .btn').css("font-size", style.style.fontSize + "px")
      }
      if (style.style.fontColor) {
        $('body').css("color", style.style.fontColor)
      }
      if (style.style.backgroundColor) {
        $('body').css("background-color", style.style.backgroundColor)
      }
      if (style.style.primaryColor) {
        $('body .btn.btn-primary').css("background-color", style.style.primaryColor)
        $('body .btn.btn-primary').css("border-color", style.style.primaryColor)
      }

      if (style.style.fontFamilyFallback) {
        $('body').css("font-family", style.style.fontFamilyFallback)
      }
      if (style.style.fontFamilyCustom) {
        WebFont.load({
          google: {
            families: [style.style.fontFamilyCustom]
          }
        });
        $('body').css("font-family", style.style.fontFamilyCustom)
      }
    } else if (style.cssType === "theme") {
      loadCSS("https://bootswatch.com/3/" + style.theme + "/bootstrap.min.css");
    } else {
      if (style.customCssUrl != null) {
        $.each(style.customCssUrl.split(";"), function (i, url) {
          loadCSS(url);
        })
      }
      $('head').append("<style>" + style.cssOverwrite + "</style>");
    }
    loadCSS('https://unpkg.com/formiojs@4.9.26/dist/formio.full.min.css')
  }
  onFormSubmit(obj) {
    obj.data.form_revision_id = this.state.metadata.form_revision_id;
    $.ajax({
      url: `${formio_backend}/forms/${this.state.form}/submit`,
      method: "POST",
      data: JSON.stringify({ data: obj.data }),
      context: this,
      error: (data) => {
        this.setState({ errorMessage: data.responseText })
        if (this.state.metadata.style.errorColor) {
          $('body .alert.alert-danger').css("background-color", this.state.metadata.style.errorColor)
        }
      },
      success: (data) => {
        if (!this.redirectToUrl(this.getRedirectUrlFromURLSearchParams()) && this.state.metadata.submit_type === "redirect") {
          this.redirectToUrl(this.state.metadata.success_redirect_url);
        }

        this.setState({ submitted: true })
      }
    })
  }
  onError(errors) {
    if (this.state.metadata.style.errorColor) {
      $('body .alert.alert-danger').css("background-color", this.state.metadata.style.errorColor)
    }

    var errorMessage = "";
    if (errors.length > 0) {
      errorMessage = errors[0].message
    }
    $.ajax(
      {
        url: `${formio_backend}/forms/errors/${this.state.form}`,
        method: "POST",
        data: {
          state: JSON.stringify({ item_id: this.state.item_id }),
          errors: errorMessage
        }
      }
    )
  }
  redirectToUrl(url) {
    if (url !== null && url !== "") {
      if (!url.match(/^[a-zA-Z]+:\/\//)) {
        url = 'https://' + url;
      }
      window.location = url
      return true;
    }
    return false
  }

  componentDidMount() {
    window.addEventListener("dragover", function (e) {
      e.preventDefault();
    }, false);
    window.addEventListener("drop", function (e) {
      e.preventDefault();
    }, false);
    $.ajax(`${formio_backend}/forms/${this.state.form}/metadata`, {
      context: this,
      error: (data) => {
        this.setState({ errorMessage: data.responseJSON.error })
      },
      success: (data) => {
        // Get form metadata
        data = data.form
        this.setState({ prefill: this.getPrefillValuesURLSearchParamsOrElem(data.body.components) })

        this.setState({ formObj: data.body })
        data.i18n = data.i18n || {}
        if (typeof data.i18n.language === "undefined") {
          data.i18n.language = "en"
        }

        if (this.getLocaleURLSearchParamsOrElem() !== null) {
          data.i18n.language = this.getLocaleURLSearchParamsOrElem()
        }
        if (document.getElementById('momentumtools-form').getAttribute('set-title') !== null) {
          window.document.title = data.title
        }
        if (document.getElementById('momentumtools-form').getAttribute('set-manifest') !== null) {
          var myDynamicManifest = {
            "name": data.title,
            "short_name": data.title,
            "description": data.title,
            "start_url": window.location.href,
            "icons": [
              {
                "src": "favicon.ico",
                "sizes": "64x64 32x32 24x24 16x16",
                "type": "image/x-icon"
              }
            ],
            "display": "standalone",
            "theme_color": "#000000",
            "background_color": "#ffffff"
          }
          const stringManifest = JSON.stringify(myDynamicManifest);
          const blob = new Blob([stringManifest], { type: 'application/json' });
          const manifestURL = URL.createObjectURL(blob);
          document.querySelector('#dynamic-forms-manifest').setAttribute('href', manifestURL);

        }

        this.setState({ metadata: data })
        //data.style = data.style||{}
        this.setStyle(data)

        if (this.state.item_id === null || this.state.item_id === "") {
          this.setState({ submissionObj: { data: this.state.prefill } })
          return;
        }
        $.ajax(
          `${formio_backend}/forms/${this.state.form}/items/${this.state.item_id}`,
          {
            context: this,
            success: (data) => {
              if (data.error) {
                this.setState({ errorMessage: data.error })
                return
              }
              var item_values = data["item_values"]

              if (typeof data.errorMessage !== 'undefined') {
                this.setState({ errorMessage: data.errorMessage })
              } else {
                this.setState({ submissionObj: { data: { ...Object.assign(item_values, this.state.prefill), item_id: this.state.item_id } } })
              }
            }
          }
        )
      }
    })

  }
  getLocaleURLSearchParamsOrElem() {
    return document.getElementById('momentumtools-form').getAttribute('data-lang') || new URLSearchParams(window.location.search).get("lang");
  }
  getPrefillValuesURLSearchParamsOrElem(components) {
    return { ...this.getPrefillValuesFromURLSearchParams(components), ...this.getPrefillValuesFromElem(components) }
  }
  getPrefillValuesFromElem(components) {
    return JSON.parse(document.getElementById('momentumtools-form').getAttribute('data-prefill'))
  }
  getPrefillValuesFromURLSearchParams(components) {
    function convertURLSearchParams(v) {
      if (!isNaN(v)) return parseInt(v, 10);
      if (v === "true") v = true;
      if (v === "false") v = false;
      return v;
    }

    var searchParams = new URLSearchParams(window.location.search);
    var prefill = {};

    for (var key of searchParams.keys()) {
      var regex = /(.*)\[(.*)\]/gm; // regex for hash query
      var match = regex.exec(key)
      var component = getComponent(components, key)
      if (typeof component !== "undefined") {
        if (component.type === "select") {
          if (component.valueProperty === "value") {
            prefill[key] = searchParams.get(key)
          } else {
            var rels = searchParams.get(key).split("||").map(function (rel) {
              var tkns = rel.split("_");
              return { title: tkns[1], podio_id: parseInt(tkns[0], 10) }
            })
            prefill[key] = rels;
          }
        } else {
          if (match === null) {
            prefill[key] = convertURLSearchParams(searchParams.get(key))
          } else {
            prefill[match[1]] = { ...prefill[match[1]], [match[2]]: convertURLSearchParams(searchParams.get(key)) }
          }
        }
      } else {
        if (match !== null) {
          prefill[match[1]] = { ...prefill[match[1]], [match[2]]: convertURLSearchParams(searchParams.get(key)) }
        }
      }
    }
    return Object.keys(prefill).length === 0 ? null : prefill;
  }

  getRedirectUrlFromURLSearchParams() {
    return new URLSearchParams(window.location.search).get("redirect_url") || document.getElementById('momentumtools-form').getAttribute('data-redirect-url')
  }

  getFormFromURLSearchParamsOrElem() {
    return this.getFormFromURLSearchParams() || this.getFormFromElem()
  }
  getFormFromURLSearchParams() {
    var searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("form")
  }
  getFormFromElem() {
    return document.getElementById('momentumtools-form').getAttribute('data-form')
  }

  getItemIdFromURLSearchParams() {
    var searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("item_id") || document.getElementById('momentumtools-form').getAttribute('data-item-id')
  }

  render() {
    const { i18n, language } = this.state.metadata.i18n
    if (typeof flatpickr.l10ns[language] !== "undefined")
      flatpickr.localize(flatpickr.l10ns[language]);

    if (this.state.errorMessage !== null) {
      return (<div>
        <h3>{this.state.errorMessage}</h3>
      </div>)
    }



    if (this.state.item_id === null && typeof this.state.metadata.i18n.language !== "undefined") {
      return (
        <div>
          {
            this.state.submitted ?
              <div className="alert alert-success" role="alert" style={{ backgroundColor: this.state.metadata.style.successColor }}><p dangerouslySetInnerHTML={{ __html: this.state.metadata.success_message }}></p></div>
              :
              <Form url={"http://127.0.0.1:3000"} form={this.state.formObj} submission={this.state.submissionObj} onError={this.onError.bind(this)} onSubmit={this.onFormSubmit.bind(this)} options={this.state.metadata.i18n} />
          }
        </div>
      );
    }

    if (this.state.submissionObj !== null && typeof this.state.metadata.i18n.language !== "undefined") {
      return (
        <div>
          {
            this.state.submitted ?
              <div className="alert alert-success" role="alert" style={{ backgroundColor: this.state.metadata.style.successColor }}><p dangerouslySetInnerHTML={{ __html: this.state.metadata.success_message }}></p></div>
              :
              <Form url={"http://127.0.0.1:3000"} form={this.state.formObj} submission={this.state.submissionObj} onError={this.onError.bind(this)} onSubmit={this.onFormSubmit.bind(this)} options={this.state.metadata.i18n} />
          }
        </div>
      );
    } else {
      return (null)
    }

  }
}

export default App;
