import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './df_wysiwyg.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
if(document.getElementById('momentumtools-form').dataset.waitForPrefillReady === "true"){
  window.$(document).on('prefill-ready', function() {
    console.log("Waited for prefill")
    ReactDOM.render(<App />, document.getElementById('momentumtools-form'));
    registerServiceWorker();
  });
}else{
  ReactDOM.render(<App />, document.getElementById('momentumtools-form'));
  registerServiceWorker();
}
